import React from 'react'

import Box from 'components/Box'
import Flex from 'components/Flex'
// import Text from 'components/Text'
import Image from 'components/Image'
import ContentLayout from 'components/ContentLayout'
import { responsive } from 'components/ThemeProvider/theme';

// import useResponsive from 'contexts/mediaQuery/useResponsive'

import text from './text.png'
import error from './error.png'

const Duplicated = ({ title, ...props }) => {
  return (
    <ContentLayout title={title} {...props}>
      <Flex flexDirection="column" alignItems="center">
        <Box width={responsive('10em', '12em')}><Image src={error} alt="打叉的Icon" /></Box>
        <Box width={responsive('10em', '16em')} mt="1.25em"><Image src={text} alt="重複聲請" /></Box>
      </Flex>
    </ContentLayout>
  )
}

export default Duplicated
